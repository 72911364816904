var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customer
    ? _c("CustomerCard", {
        attrs: {
          customer: _vm.customer,
          hideCashback: _vm.hideCashback,
          hidePoints: _vm.hidePoints
        }
      })
    : _c("v-skeleton-loader", {
        attrs: { type: "card-avatar, article, actions" }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }