<template>
    <v-alert v-if="hasError" type="error">
        {{ $t('errors.generic') }}
    </v-alert>
    <v-card flat v-else>
        <v-progress-linear indeterminate v-if="loading"/>
        <v-list v-else class="pt-5 px-0 px-md-6 font-weight-bold list-title">
            <span class="pl-7 pl-md-1 primary--text">{{ $t('views.History.list_title') }}</span>

            <div v-if="items && items.length" class="mt-6 mx-6 mx-md-0">
                <HistoryDialog v-for="(item, index) in items" :key="index" :item="item"/>
            </div>
            <div v-else class="text-center">
                <div class="py-6 text-center title" color="red">
                    {{ $t('views.History.no_registers') }}
                </div>
            </div>
        </v-list>
        <v-card-text v-if="!loading" class="text-center py-0">
            <SimplePaginator
                :pagination.sync="pagination"
                :items="items"
                hidePerPage
                class="pb-1"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import HistoryDialog   from '@/shared/components/dialogs/HistoryDialog'
import Consumption     from '@/models/Consumption'
import Generator       from '@/models/Generator'
import Reversal        from '@/models/Reversal'
import SimplePaginator from '@/shared/components/paginators/SimplePaginator'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'


export default {
    name: 'HistoryList',
    props: {
        limitDays: {
            type: Number,
            required: false,
        }, // [TODO][FID-849] Implementar filtro por período no histórico
    },
    mixins: [ HasErrorHandlerMixin ],
    components: { HistoryDialog, SimplePaginator },
      data: vm => ({
        loading : false,
        hasError: false,

        pagination: {
            page    : 1,
            per_page: 5,
        },

        items: [],
    }),
    methods: {
        async fetchFunction() {
            let response = await this.$axios({
                url: 'customer/history',
                method: 'get',
                params: {
                    page             : this.pagination.page,
                    per_page         : this.pagination.per_page,
                    order            : 'DESC',
                    include_reversals: true,
                },
            }).catch(this.preErrorHandler)

            if (!response)
                return

            let modelItems = []

            // Transforma em modelos
            response.data.forEach(register => {
                // Todas e somente consumptions tem intention_id
                // Todas e somente reversal tem generator_id
                // Isso será usado para diferencia-las de generators
                let Model = register.intention_id ? Consumption : register.source_id ? Reversal : Generator
                modelItems.push(new Model(register))
            })

            return modelItems
        }, 
        async preFetchItems() {
            await this.fetchItems()
                .catch(this.preErrorHandler)
        },
        async fetchItems() {
            this.loading = true
            this.items   = []
            this.items   = await this.fetchFunction(this.pagination)
                .catch(this.preErrorHandler)
            this.loading = false
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
        refresh() {
            this.items = []
            // Se já está na página 1, não vai para o watch, então disparo manualmente
            if (this.pagination.page == 1)
                this.preFetchItems()

            this.pagination.page = 1
            this.pagination.per_page = this.perPage
        },
    },
    watch: {
        'pagination.page': {
            immediate: true,
            async handler() {
                await this.preFetchItems()
            },
        },
    },
}
</script>

<style lang="scss">
.list-title {
    font-size: 16px;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped), .v-item-group {
    border-radius: 8px !important;
}
</style>