var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-breadcrumbs",
                {
                  staticClass: "pl-0 pb-1",
                  class: _vm.isLightTheme ? "breadcrumb-item" : "",
                  attrs: { items: _vm.breadcrumbs }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "breadcrumbs-chevro",
                      attrs: { slot: "divider" },
                      slot: "divider"
                    },
                    [_vm._v("fas fa-chevron-right")]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "section-title font-weight-bold primary_font--text"
                },
                [_vm._v(_vm._s(_vm.$t("views.History.title")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.smAndDown ? "pt-0 mt-n6" : "" },
        [
          _c(
            "v-col",
            { class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-1" : "px-0" },
            [
              _c(
                "v-row",
                { staticClass: "px-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0",
                      class: _vm.$vuetify.breakpoint.smAndUp
                        ? "px-3"
                        : "px-3 mt-4 customer-balance",
                      attrs: { cols: "12", sm: "12", md: "4" }
                    },
                    [
                      _c("CustomerHistoryBalance", {
                        attrs: { customer: _vm.user }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.mdAndDown
                        ? "px-0"
                        : "px-3 customer-history",
                      attrs: { cols: "12", sm: "12", md: "8" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pr-1" },
                        [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "px-sm-4 mb-3",
                              attrs: {
                                height: "40px",
                                color: "primary",
                                "slider-color": "primary",
                                "background-color": "background",
                                "show-arrows": ""
                              },
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            _vm._l(_vm.tabs, function(item, index) {
                              return _c(
                                "v-tab",
                                {
                                  key: index,
                                  staticClass: "tab-title",
                                  attrs: {
                                    disabled: item.disabled,
                                    "active-class": "tab-title-active"
                                  }
                                },
                                [_vm._v(" " + _vm._s(item.title) + " ")]
                              )
                            }),
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            _vm._l(_vm.tabs, function(item, index) {
                              return _c(
                                "v-tab-item",
                                { key: index },
                                [
                                  _c("HistoryList", {
                                    attrs: { limitDays: item.limitDays }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }