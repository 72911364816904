var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasError
    ? _c("v-alert", { attrs: { type: "error" } }, [
        _vm._v(" " + _vm._s(_vm.$t("errors.generic")) + " ")
      ])
    : _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _vm.loading
            ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
            : _c(
                "v-list",
                {
                  staticClass: "pt-5 px-0 px-md-6 font-weight-bold list-title"
                },
                [
                  _c("span", { staticClass: "pl-7 pl-md-1 primary--text" }, [
                    _vm._v(_vm._s(_vm.$t("views.History.list_title")))
                  ]),
                  _vm.items && _vm.items.length
                    ? _c(
                        "div",
                        { staticClass: "mt-6 mx-6 mx-md-0" },
                        _vm._l(_vm.items, function(item, index) {
                          return _c("HistoryDialog", {
                            key: index,
                            attrs: { item: item }
                          })
                        }),
                        1
                      )
                    : _c("div", { staticClass: "text-center" }, [
                        _c(
                          "div",
                          {
                            staticClass: "py-6 text-center title",
                            attrs: { color: "red" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("views.History.no_registers")) +
                                " "
                            )
                          ]
                        )
                      ])
                ]
              ),
          !_vm.loading
            ? _c(
                "v-card-text",
                { staticClass: "text-center py-0" },
                [
                  _c("SimplePaginator", {
                    staticClass: "pb-1",
                    attrs: {
                      pagination: _vm.pagination,
                      items: _vm.items,
                      hidePerPage: ""
                    },
                    on: {
                      "update:pagination": function($event) {
                        _vm.pagination = $event
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }