<template>
    <CustomerCard
        v-if="customer"
        :customer="customer"
        :hideCashback="hideCashback"
        :hidePoints="hidePoints"
    />
    <v-skeleton-loader
        v-else
        type="card-avatar, article, actions"
    ></v-skeleton-loader>
</template>

<script>
import SimpleCustomer from '../../models/SimpleCustomer'
import CustomerCard   from '@/shared/components/cards/CustomerCard.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'CustomerHistoryBalance',
    components: { CustomerCard },
    props: {
        customer: [ SimpleCustomer ],
        showHistoryButton: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters({
            hideCashback: 'company/hide_cashback',
            hidePoints: 'company/hide_points',
        }),
    },
}
</script>