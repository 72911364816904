<template>
    <div>
       <v-container>
           <v-col class="pa-0">
                <v-breadcrumbs :class="isLightTheme ? 'breadcrumb-item': ''" class="pl-0 pb-1" :items="breadcrumbs">
                    <v-icon slot="divider" class="breadcrumbs-chevro">fas fa-chevron-right</v-icon>
                </v-breadcrumbs>

                <div class="section-title font-weight-bold primary_font--text">{{ $t('views.History.title') }}</div>
           </v-col>
        </v-container>

        <v-container :class="$vuetify.breakpoint.smAndDown ? 'pt-0 mt-n6' : ''">
            <v-col :class="$vuetify.breakpoint.mdAndUp ? 'ml-1' : 'px-0'">
                <v-row class="px-0">
                    <v-col class="px-0" cols="12" sm="12" md="4" :class="$vuetify.breakpoint.smAndUp ? 'px-3' : 'px-3 mt-4 customer-balance'">
                        <CustomerHistoryBalance :customer="user" />
                    </v-col>

                    <v-col cols="12" sm="12" md="8" :class="$vuetify.breakpoint.mdAndDown ? 'px-0': 'px-3 customer-history'">
                        <div class="pr-1">
                            <v-tabs
                                v-model="tab"
                                height="40px"
                                color="primary"
                                slider-color="primary"
                                background-color="background"
                                class="px-sm-4 mb-3"
                                show-arrows
                            >
                                <v-tab
                                    v-for="(item, index) of tabs"
                                    :key="index"
                                    :disabled="item.disabled"
                                    class="tab-title"
                                    active-class="tab-title-active"
                                >
                                    {{ item.title }}
                                </v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab">
                                <v-tab-item
                                    v-for="(item, index) of tabs"
                                    :key="index"
                                >
                                    <HistoryList
                                        :limitDays="item.limitDays"
                                    ></HistoryList>
                                </v-tab-item>
                            </v-tabs-items>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-container>
    </div>
</template>

 <script>
import RateColor              from '@/shared/mixins/RateColor'
import CustomerHistoryBalance from './CustomerHistoryBalance'
import HistoryList            from '@/components/lists/HistoryList'
import { mapGetters }         from 'vuex'

export default {
    mixins: [ RateColor ],
    components: { 
        CustomerHistoryBalance,
        HistoryList
        },
    data: vm => {
        return {
             breadcrumbs: [
                { text: vm.$t('drawer.home'), to: { name: 'home' } },
                { text: vm.$t('drawer.history'), disabled: true    },
            ],
            tab: null,
        }
    },
    computed: {
        ...mapGetters({
            user  : 'auth/user',
        }),
        tabs() {
            return [
                { title: this.$t('views.History.tabs.all'),          limitDays: undefined, disabled: false },
                { title: this.$t('views.History.tabs.fifteen_days'), limitDays: 15       , disabled: true  },
                { title: this.$t('views.History.tabs.thirty_days'),  limitDays: 30       , disabled: true  },
            ]
        },
    },
}
</script>
