import BaseModel from '@/models/BaseModel'

export default class Customer extends BaseModel {

    defaults() {
        return {
            id                   : null,
            firebase_uid         : null,
            email                : null,
            phone                : null,
            name                 : null,
            birthdate            : null,
            document             : null,
            address              : null,
            avatar_url           : null,
            firebaseResult       : null,
            login_type           : null,
            emailVerified        : null,
            firebaseAction       : null,
            require_password     : true,
            // Informações complexas
            balance              : {
                amount_balance : 0,
                received_amount: 0,
                spent_value    : 0,
                value_balance  : 0,
            },
            levels_system: {
                current: undefined,
                next   : undefined,
            },
            // Informações auxiliares sobre níveis
            level_reset_pending: false,

            use_cnpj  : false,
            created_at: null,
            updated_at: null,
        }
    }
}